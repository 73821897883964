import data from '../../fields';
//

export default {
  requiredFields: () => {
    return [
      'violation_type_id',
      'owner_is_known',
      'obj_type_id',
      'area_id',
      'address_district_id',
      'address_full',
      'geo_polygon',
      'audit_doc_number',
      'audit_doc_date',
      'file_audit_doc_files',
      'file_audit_doc_photos',
      'warranty_measures_taken'
    ];
  },
  sections: (object, areaId) => {
    function getFiles(folder) {
      return object.files.filter(el => el.folder_code == folder);
    }

    function isObj() {
      return object ? true : false;
    }

    const geoPoint = object.geo_polygon?.[0]?.coordinates?.[0]?.[0];

    const getMapZoom = () => {
      if (geoPoint) return 18;
      return 10;
    };

    return [
      {
        title: 'ВЫЯВЛЕНИЕ ОБЪЕКТА',
        editable: true,
        params: [
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title: 'УЧЕТНЫЙ НОМЕР ОБЪЕКТА И ДАТА ПОСТАНОВКИ НА УЧЕТ'
            }
          },
          {
            label: '',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: isObj(),
              placeholder: ''
              //modelValue: object.obj_registration_number || '',
            },
            property: 'obj_registration_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: isObj(),
              name: 'obj_registration_date',
              placeholder: ''
            },
            property: 'obj_registration_date'
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title: 'ОБЩИЕ СВЕДЕНИЯ'
            }
          },
          {
            label: 'ТИП НАРУШЕНИЯ',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              //modelValue: object.violation_type_id || '',
              items: data.violationTypes(),
              placeholder: 'Выберите тип нарушения'
            },
            property: 'violation_type_id'
          },
          {
            label: 'СОБСТВЕННИК ИЗВЕСТЕН',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object.owner_is_known || '',
              name: 'owner_is_known',
              items: data.boolRadio()
            },
            required: ['owner_info'],
            fieldClass: 'mb-10',
            property: 'owner_is_known'
          },
          {
            label: 'ИНФОРМАЦИЯ О СОБСТВЕННИКЕ',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите описание',
              //modelValue: object.owner_info || '',
              rows: 3
            },
            property: 'owner_info'
          },
          {
            label: 'ТИП ОБЪЕКТА И ВИД ОБЪЕКТА',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              //modelValue: object.obj_type_id || '',
              items: data.objectTypes(4),
              placeholder: 'Выберите тип объекта'
            },
            property: 'obj_type_id'
          },
          {
            label: 'ГО / МР',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              disabled: object.obj_registration_number,
              items: data.areas()
            },
            property: 'area_id',
            type: 'form_generator'
          },
          {
            label: 'РАЙОН ОБЪЕКТА',
            component: 'uiSelect',
            fieldClass: 'mb-20',
            componentAttrs: {
              items: data.districts(areaId),
              placeholder: 'Выберите район',
              disabled:
                !data.districts(areaId).length == 1 ||
                areaId == -1 ||
                object.obj_registration_number
            },
            property: 'address_district_id',
            type: 'form_generator'
          },
          {
            label: 'АДРЕС ОБЪЕКТА',
            component: 'auto-address',
            fieldClass: 'mb-20',
            componentAttrs: {
              object: object,
              placeholder: '',
              areaId: areaId,
              disabled: object.obj_registration_number
            },
            property: 'address_full',
            type: 'form_generator'
          },
          {
            label: 'ОПИСАНИЕ АДРЕСА',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите описание',
              //modelValue: object.address_info || '',
              rows: 3
            },
            property: 'address_info'
          },
          {
            component: 'mapPlaceAsot',
            fieldClass: 'mb-10',
            componentAttrs: {
              polygon: data.getPolygon(object.area_id),
              disabled: object.obj_registration_number != null,
              center: geoPoint || data.getPolygon(object.area_id, true),
              zoom: getMapZoom(),
              types: ['polygon'],
              defaultType: 'polygon'
            },
            property: 'geo_polygon'
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title:
                'РЕКВИЗИТЫ АКТА ПРОВЕРКИ, УСТАНАВЛИВАЮЩЕГО ФАКТ ВЫЯВЛЕНИЯ НЕЗАКОННОГО ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: object.guid,
              placeholder: 'Введите номер'
            },
            property: 'audit_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled: object.guid,
              name: 'property'
            },
            property: 'audit_doc_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_audit_doc_files',
            componentAttrs: {
              disabled_edit: object.guid,
              border_top: true,
              tooltipText: 'Акт проверки',
              proxyData: getFiles('audit_doc_files'),
              allowMultiple: 'true',
              uploadFolder: 'audit_doc_files'
            },
            type: 'form_generator'
          },
          {
            component: 'editPhotos',
            property: 'file_audit_doc_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              disabled_edit: object.guid,
              border_top: false,
              title: '',
              proxyData: getFiles('audit_doc_photos'),
              allowMultiple: 'true',
              uploadFolder: 'audit_doc_photos'
            }
          },
          {
            label: 'Кадастровый номер земельного участка',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
            },
            property: 'land_cadastral_number'
          },
          {
            label: 'Площадь земельного участка, кв.м',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите число'
            },
            property: 'land_area'
          },
          {
            label: 'Количество машино-мест, всего',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите число'
            },
            property: 'parking_place_total_cnt'
          },
          {
            label:
              'Количество машино-мест, в т.ч. для парковки специальных автотранспортных средств инвалидов',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите число'
            },
            property: 'parking_place_with_disabled_cnt'
          }
        ]
      },
      {
        title: 'ОБЕСПЕЧИТЕЛЬНЫЕ МЕРЫ',
        editable: true,
        params: [
          {
            label: 'НАЛОЖЕНЫ ЛИ ОБЕСПЕЧИТЕЛЬНЫЕ МЕРЫ?',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object.warranty_measures_taken || '',
              name: 'warranty_measures_taken',
              items: data.boolRadio()
            },
            fieldClass: 'mb-10',
            property: 'warranty_measures_taken'
          },
          {
            label: 'КОММЕНТАРИЙ',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите комментарий',
              //modelValue: object.warranty_measures_info || '',
              rows: 3
            },
            property: 'warranty_measures_info'
          }
        ]
      },
      {
        title: 'ДОБРОВОЛЬНЫЙ ДЕМОНТАЖ',
        editable: true,
        params: [
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title: 'ПЕРИОД, ОТВЕДЕННЫЙ НА ДОБРОВОЛЬНЫЙ ДЕМОНТАЖ ОБЪЕКТА'
            }
          },
          {
            label: 'НАЧАЛО И ОКОНЧАНИЕ',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_voluntary_date_from'
              //modelValue: object.remove_voluntary_date_from || '',
            },
            required: ['remove_voluntary_date_to'],
            property: 'remove_voluntary_date_from'
          },
          {
            label: '-',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_voluntary_date_to'
              //modelValue: object.remove_voluntary_date_to || '',
            },
            property: 'remove_voluntary_date_to'
          },
          {
            component: 'formSectionTitle',
            componentAttrs: {
              title:
                'СВЕДЕНИЯ О ПРОДЛЕНИИ ПЕРИОДА, ОТВЕДЕННОГО НА ДОБРОВОЛЬНЫЙ ДЕМОНТАЖ ОБЪЕКТА'
            }
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'ml-10',
            componentAttrs: {
              title:
                'СВЕДЕНИЯ ОБ АКТЕ ТЕРРИТОРИАЛЬНОГО ОРГАНА АДМИНИСТРАЦИИ ГОРОДА О ПРОДЛЕНИИ'
            }
          },
          {
            label: 'НАИМЕНОВАНИЕ',
            component: 'uiInput',
            fieldClass: 'mb-10 ml-10',
            componentAttrs: {
              placeholder: 'Введите наименование'
              //modelValue: object.remove_ext_doc_type_name || '',
            },
            property: 'remove_ext_doc_type_name'
          },
          {
            label: 'НОМЕР И ДАТА СОСТАВЛЕНИЯ',
            component: 'uiInput',
            fieldClass: 'mb-10 ml-10',
            componentAttrs: {
              placeholder: 'Введите наименование'
              //modelValue: object.remove_ext_doc_number || '',
            },
            property: 'remove_ext_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10 ml-10',
            componentAttrs: {
              name: 'remove_ext_doc_date'
              //modelValue: object.remove_ext_doc_date || '',
            },
            property: 'remove_ext_doc_date'
          },
          {
            label: 'СРОК ПРОДЛЕНИЯ',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_ext_date_to'
              //modelValue: object.remove_ext_date_to || '',
            },
            property: 'remove_ext_date_to'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'СВЕДЕНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА'
            }
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'АКТ ПРОВЕРКИ ИСПОЛНЕНИЯ ТРЕБОВАНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ'
            }
          },
          {
            label: 'НОМЕР И ДАТА СОСТАВЛЕНИЯ',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
              //modelValue: object.remove_voluntary_doc_number || '',
            },
            property: 'remove_voluntary_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_voluntary_doc_date'
              //modelValue: object.remove_voluntary_doc_date || '',
            },
            property: 'remove_voluntary_doc_date'
          },
          {
            label:
              'РЕЗУЛЬТАТЫ ПРОВЕРКИ ИСПОЛНЕНИЯ ТРЕБОВАНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ (ДЕМОНТИРОВАН/ НЕ ДЕМОНТИРОВАН)',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              //modelValue: object.remove_voluntary_is_done || '',
              name: 'remove_voluntary_is_done',
              items: data.boolRadio()
            },
            fieldClass: 'mb-10',
            property: 'remove_voluntary_is_done'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_remove_voluntary_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'акт проверки исполнения требования',
              proxyData: getFiles('remove_voluntary_files'),
              allowMultiple: 'true',
              uploadFolder: 'remove_voluntary_files'
            }
          },
          {
            component: 'editPhotos',
            property: 'file_remove_voluntary_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              border_top: true,
              title: '',
              proxyData: getFiles('remove_voluntary_photos'),
              allowMultiple: 'true',
              uploadFolder: 'remove_voluntary_photos'
            }
          }
        ]
      },
      {
        title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ',
        editable: true,
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'РЕКВИЗИТЫ РАСПОРЯЖЕНИЯ ГЛАВЫ ТЕРРИТОРИАЛЬНОГО ОРГАНА О ПРИНУДИТЕЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР СЭД И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
              //modelValue: object.remove_force_doc_number || '',
            },
            required: [
              'remove_force_doc_date',
              'remove_force_bulletin_number',
              'remove_force_bulletin_date',
              'file_remove_force_files',
              'remove_plan_date'
            ],
            property: 'remove_force_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_force_doc_date'
              //modelValue: object.remove_force_doc_date || '',
            },
            property: 'remove_force_doc_date'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'СВЕДЕНИЯ ОБ ОФИЦИАЛЬНОМ ОПУБЛИКОВАНИИ'
            }
          },
          {
            label: '№ ОФИЦИАЛЬНОГО БЮЛЛЕТЕНЯ ОРГАНОВ МЕСТНОГО САМОУПРАВЛЕНИЯ',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
              //modelValue: object.remove_force_bulletin_number || '',
            },
            property: 'remove_force_bulletin_number'
          },
          {
            label: 'ДАТА',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_force_bulletin_date'
              //modelValue: object.remove_force_bulletin_date || '',
            },
            property: 'remove_force_bulletin_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_remove_force_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'распоряжение  о принудительном демонтаже',
              proxyData: getFiles('remove_force_files'),
              allowMultiple: 'true',
              uploadFolder: 'remove_force_files'
            }
          },
          {
            label: 'ДАТА ПЛАНИРУЕМОГО ДЕМОНТАЖА ОБЪЕКТА',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_plan_date'
              //modelValue: object.remove_plan_date || '',
            },
            property: 'remove_plan_date'
          },
          {
            label: 'ДАТА ФАКТИЧЕСКОГО ДЕМОНТАЖА ОБЪЕКТА',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_actual_date'
              //modelValue: object.remove_actual_date || '',
            },
            property: 'remove_actual_date'
          }
        ]
      },
      {
        title: 'ПОВТОРНЫЕ СВЕДЕНИЯ О ДОБРОВОЛЬНОМ ДЕМОНТАЖЕ ОБЪЕКТА',
        editable: true,
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'Повторные сведения о добровольном демонтаже объекта'
            }
          },
          {
            label: 'Добровольный демонтаж',
            component: 'uiRadio',
            componentAttrs: {
              column: false,
              name: 'sec_remove_voluntary_is_done',
              items: data.boolRadio()
            },
            required: [
              'sec_remove_voluntary_doc_number',
              'sec_remove_voluntary_doc_date',
              'file_sec_remove_voluntary_files',
              'file_sec_remove_voluntary_photos'
            ],
            fieldClass: 'mb-10',
            property: 'sec_remove_voluntary_is_done'
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'Акт проверки исполнения требования о добровольном демонтаже'
            }
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            label: 'Номер и дата Составления',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
              //modelValue: object.sec_remove_voluntary_doc_number || '',
            },
            property: 'sec_remove_voluntary_doc_number'
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'sec_remove_voluntary_doc_date'
              //modelValue: object.sec_remove_voluntary_doc_date || '',
            },
            property: 'sec_remove_voluntary_doc_date'
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_sec_remove_voluntary_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'акт проверки исполнения требования',
              proxyData: getFiles('sec_remove_voluntary_files'),
              allowMultiple: 'true',
              uploadFolder: 'sec_remove_voluntary_files'
            }
          },
          {
            _if: object => {
              return object.sec_remove_voluntary_is_done;
            },
            component: 'editPhotos',
            property: 'file_sec_remove_voluntary_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              border_top: true,
              title: '',
              proxyData: getFiles('sec_remove_voluntary_photos'),
              allowMultiple: 'true',
              uploadFolder: 'sec_remove_voluntary_photos'
            }
          }
        ]
      },
      {
        title: 'ПЕРЕМЕЩЕНИЕ И ХРАНЕНИЕ ОБЪЕКТА',
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'СВЕДЕНИЯ ОБ АКТЕ О ДЕМОНТАЖЕ И ПЕРЕМЕЩЕНИИ ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
              //modelValue: object.remove_act_number || '',
            },
            property: 'remove_act_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'remove_act_date'
              //modelValue: object.remove_act_date || '',
            },
            property: 'remove_act_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_remove_act_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'акт о демонтаже и перемещении объекта',
              proxyData: getFiles('remove_act_files'),
              allowMultiple: 'true',
              uploadFolder: 'remove_act_files'
            }
          },
          {
            component: 'editPhotos',
            property: 'file_remove_act_photos',
            fieldClass: 'mb-10',
            componentAttrs: {
              border_top: true,
              title:
                'фотографии объекта: акт о демонтаже и перемещении объекта',
              proxyData: getFiles('remove_act_photos'),
              allowMultiple: 'true',
              uploadFolder: 'remove_act_photos'
            }
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'СВЕДЕНИЯ О ХРАНЕНИИ ДЕМОНТИРОВАННОГО ОБЪЕКТА'
            }
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'МЕСТО ХРАНЕНИЯ ОБЪЕКТА'
            }
          },
          {
            label: 'АДРЕС ОБЪЕКТА, ОТВЕТСТВЕННЫЙ',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              //modelValue: object.keep_place_id || '',
              items: data.keepPlaces(),
              placeholder: 'Выберите место хранения'
            },
            property: 'keep_place_id'
          },
          {
            label: ' ОКОНЧАНИЕ СРОКА ХРАНЕНИЯ',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'keep_date'
              //modelValue: object.keep_date || '',
            },
            property: 'keep_date'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'ИНФОРМАЦИЯ О ЗАХОРОНЕНИИ ЛИБО УТИЛИЗАЦИИ ДЕМОНТИРОВАННОГО ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР ДОКУМЕНТА И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
              //modelValue: object.utilization_doc_number || '',
            },
            property: 'utilization_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'utilization_date'
              //modelValue: object.utilization_date || '',
            },
            property: 'utilization_date'
          },
          {
            component: 'editFiles',
            fieldClass: 'mb-10',
            property: 'file_utilization_files',
            componentAttrs: {
              border_top: true,
              tooltipText: 'сведения о хранении демонтированного объекта',
              proxyData: getFiles('utilization_files'),
              allowMultiple: 'true',
              uploadFolder: 'utilization_files'
            }
          }
        ]
      },
      {
        title: 'СВЕДЕНИЯ О ВОЗМЕЩЕНИИ ПОНЕСЕННЫХ РАСХОДОВ',
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'Сведения о возмещении понесенных расходов'
            }
          },
          {
            label:
              'Сумма фактически понесенных расходов, связанных с осуществлением мероприятий по демонтажу, руб.',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: '0.00'
              //modelValue: object.remove_cost_actual || '',
            },
            property: 'remove_cost_actual'
          },
          {
            label:
              ' СУММА ВОЗМЕЩЕННЫХ В БЮДЖЕТ ГОРОДА РАСХОДОВ, СВЯЗАННЫХ С ОСУЩЕСТВЛЕНИЕМ МЕРОПРИЯТИЙ ПО ДЕМОНТАЖУ, ПЕРЕМЕЩЕНИЮ, ХРАНЕНИЮ САМОВОЛЬНОГО(НЕЗАКОННОГО) ОБЪЕКТА, РУБ.',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: '0.00'
              //modelValue: object.remove_cost_refunded || '',
            },
            property: 'remove_cost_refunded'
          },
          {
            label:
              'ДАТА ВОЗМЕЩЕНИЯ В БЮДЖЕТ ГОРОДА РАСХОДОВ, СВЯЗАННЫХ С ОСУЩЕСТВЛЕНИЕМ МЕРОПРИЯТИЙ ПО ДЕМОНТАЖУ, ПЕРЕМЕЩЕНИЮ, ХРАНЕНИЮ САМОВОЛЬНОГО(НЕЗАКОННОГО) ОБЪЕКТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: '0.00'
              //modelValue: object.remove_cost_refund_date || '',
            },
            property: 'remove_cost_refund_date'
          }
        ]
      },
      {
        title: 'СВЕДЕНИЯ О ДОГОВОРЕ АРЕНДЫ',
        params: [
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: ''
            }
          },
          {
            label: 'НОМЕР И ДАТА',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
              //modelValue: object.agreement_number || '',
            },
            property: 'agreement_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'agreement_date'
              //modelValue: object.agreement_date || '',
            },
            property: 'agreement_date'
          },
          {
            label: 'ВИД РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ ЗЕМЕЛЬНОГО УЧАСТКА',
            component: 'uiSelect',
            fieldClass: 'mb-10',
            componentAttrs: {
              //modelValue: object.agreement_land_usage_type_id || '',
              items: data.landUsageTypes(),
              placeholder: 'Выберите вид использования'
            },
            property: 'agreement_land_usage_type_id'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'СВЕДЕНИЯ О НАРУШЕНИИ УСЛОВИЙ ДОГОВОРА АРЕНДЫ'
            }
          },
          {
            label: 'ОПИСАНИЕ НАРУШЕНИЙ УСЛОВИЙ ДОГОВОРА',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите описание',
              //modelValue: object.lease_violation_desc || '',
              rows: 3
            },
            property: 'lease_violation_desc'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title:
                'РЕКВИЗИТЫ АКТА ПРОВЕРКИ, УСТАНАВЛИВАЮЩЕГО ФАКТ ВЫЯВЛЕНИЯ ОБЪЕКТА'
            }
          },
          {
            label: 'НОМЕР И ДАТА СОСТАВЛЕНИЯ',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
              //modelValue: object.lease_violation_doc_number || '',
            },
            property: 'lease_violation_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'lease_violation_doc_date'
              //modelValue: object.lease_violation_doc_date || '',
            },
            property: 'lease_violation_doc_date'
          },
          {
            label: 'СРОК ДЛЯ УСТРАНЕНИЯ НАРУШЕНИЙ',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'lease_violation_fix_date'
              //modelValue: object.lease_violation_fix_date || '',
            },
            property: 'lease_violation_fix_date'
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'Сведения об устранении нарушений условий договора аренды'
            }
          },
          {
            component: 'formSectionTitle',
            fieldClass: 'mb-10',
            componentAttrs: {
              title: 'Акт проверки исполнения'
            }
          },
          {
            label: 'Номер и дата Составления',
            component: 'uiInput',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите номер'
              //modelValue: object.fix_doc_number || '',
            },
            property: 'fix_doc_number'
          },
          {
            label: 'от',
            component: 'uiDatePicker',
            fieldClass: 'mb-10',
            componentAttrs: {
              name: 'fix_doc_date'
              //modelValue: object.fix_doc_date || '',
            },
            property: 'fix_doc_date'
          },
          {
            label: 'результаты проверки исполнения',
            component: 'uiTextArea',
            fieldClass: 'mb-10',
            componentAttrs: {
              placeholder: 'Введите результат',
              //modelValue: object.fix_doc_result || '',
              rows: 3
            },
            property: 'fix_doc_result'
          }
        ]
      }
    ];
  }
};
